import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { BaseHttpService } from './base-http.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MailboxService extends BaseHttpService {

    constructor(
        http: HttpClient
    ) {
        super(http);
    }

    public test() {
        return firstValueFrom(this.http.get<string>(`${this.baseApiUrl}/auth/test`, { headers: { "X-Requested-With": "XMLHttpRequest"}}));
    }

    // public list() {
    //     return firstValueFrom(this.http.get<Order[]>(`${this.baseApiUrl}/order/list`));
    // }

    // public get(id: string) {
    //     return firstValueFrom(this.http.get<string>(`${this.baseApiUrl}/mailbox/${id}`));
    // }

    // public delete(id: string) {
    //     return firstValueFrom(this.http.delete<boolean>(`${this.baseApiUrl}/order/${id}`));
    // }

    // public save(o: Order) {
    //     let order = { ...o };
    //     order.consultant = order.leadCreator = order.department = null;
    //     return firstValueFrom(this.http.post<Order>(`${this.baseApiUrl}/order/save`, order));
    // }
}
