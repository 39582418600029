import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseHttpService {
  protected baseApiUrl: string;

  constructor(
    protected http: HttpClient
  ) { 
    this.baseApiUrl = environment.apiBaseUrl;
  }
}
